'use client';

import { useEffect, useState } from 'react';

import { DESKTOP_MEDIA_QUERY } from '@/constants';
import useMediaQuery from '@/hooks/useMediaQueries';

import BurgerButton from './BurgerButton';
import Navbar from './Navbar';

import styles from '../styles.module.scss';

export type MenuState = 'menu-inactive' | 'menu-close' | 'menu-open';

function BurgerMenu() {
  const [menuState, setMenuState] = useState<MenuState>('menu-inactive');
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const isOpen = menuState === 'menu-open';

  const closeMenu = () => {
    if (isOpen && !isDesktop) {
      setMenuState('menu-close');
    }
  };

  const toggleMenu = () => {
    if (isOpen) {
      closeMenu();
    } else {
      setMenuState('menu-open');
    }
  };

  useEffect(() => {
    const isInactive = menuState === 'menu-inactive';

    if (isDesktop && !isInactive) {
      setMenuState('menu-inactive');
    }
  }, [isDesktop, menuState]);

  return (
    <>
      <div className={styles.overlay} onClick={closeMenu} />
      <div className={styles.startIcon}>
        <BurgerButton
          onToggle={toggleMenu}
          isOpen={isOpen}
          menuState={menuState}
        />
      </div>
      <Navbar closeMenu={closeMenu} className={styles.navbar} />
    </>
  );
}

export default BurgerMenu;
