import { OrderAPI } from '@/app/(Shop)/refund/context/RefundOrder/type';
import { HTTPStatus } from '@/constants';
import hasError from '@/services/backendApi/utils/hasError';
import {
  CheckoutDataResponse,
  HistoryOrder,
  OrderStatus,
  PostOrder,
  PutOrderResponse,
} from '@/types/order';

import fetchInternalApi from '../backendApi/utils/fetchInternalApi';
import throwError from '../backendApi/utils/throwError';

export const getOrders = async () => {
  const data = await fetchInternalApi<{ orders: HistoryOrder[] }>(`orders`);

  if (hasError(data))
    throw new Error('Error: data undefined on getOrders(client)');

  return data.orders;
};

export const putOrder = async (orderId: string, requestBody: PostOrder) => {
  const data = await fetchInternalApi<PutOrderResponse>(`orders/${orderId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(requestBody),
  });

  return data;
};

export const patchOrder = async (
  orderId: string,
  requestBody: { status: OrderStatus },
) => {
  const data = await fetchInternalApi<PutOrderResponse>(`orders/${orderId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(requestBody),
  });
  return hasError(data) ? [] : data;
};

export const postCheckout = async (
  orderId: string,
  requestBody: Record<string, unknown>,
) => {
  const data = await fetchInternalApi<CheckoutDataResponse>(
    `orders/${orderId}/checkout`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(requestBody),
    },
  );
  return data;
};

export const postRefund = async (
  orderId: string,
  requestBody: Record<string, unknown>,
) => {
  const data = await fetchInternalApi<{ status: number; message: string }>(
    `orders/${orderId}/refund`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(requestBody),
    },
  );

  const messages: Record<number, string> = {
    [HTTPStatus.NOT_FOUND]: 'Your order is not found',
    [HTTPStatus.BAD_REQUEST]:
      'We have a technical issue on our side. Please try again from your email',
    [HTTPStatus.CONFLICT]: 'Your order has already been refunded',
    [HTTPStatus.ERROR_API]:
      'We have a technical issue on our side. Please try again from your email',
    [HTTPStatus.OK]: 'Your card has not been debited yet for this order',
  };
  const explainMessage = data.status ? messages[data.status] : undefined;

  return { succeded: !data.message, message: explainMessage };
};

export const getRefund = async (orderId: string) => {
  const data = await fetchInternalApi<OrderAPI>(`orders/${orderId}/short`, {
    cache: 'no-store',
  });

  return throwError(data) ? undefined : data;
};
