'use client';

import { useCallback, useState } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
}

export default function HeaderContainer({ children }: Props) {
  const [isSticky, setIsSticky] = useState(false);

  const wrapperRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      window.addEventListener('scroll', () => {
        // When we scroll header height
        setIsSticky(window.scrollY > 55);
      });
    }
  }, []);
  return (
    <div
      className={clsx(styles.container, isSticky && styles.isSticky)}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
}
