import clsx from 'clsx';
import Link from 'next/link';

import Icon from '@/components/Icon';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  closeMenu: () => void;
};

export default function Navbar({ className, closeMenu }: Props) {
  type NavLink = {
    label: string;
    url: string;
    isFeatured?: boolean;
  };

  const navLinks: NavLink[] = [
    {
      label: 'Cards',
      url: '/cards',
    },
    {
      label: 'Photo Prints',
      url: '/product/prints',
    },
    {
      label: 'Photo Posters',
      url: '/product/posters',
    },
    {
      label: 'Wedding',
      url: '/events/wedding',
      isFeatured: true,
    },
  ];

  return (
    <nav className={clsx(styles.navbar, className)}>
      <ul className={styles.navList}>
        {navLinks.map((nav) => (
          <li key={nav.url} className={clsx('bodyMedium', styles.navItem)}>
            {nav.isFeatured ? (
              <>
                <span className={styles.heart1}>
                  <Icon name="heartPicta" />
                </span>
                <span className={styles.heart2}>
                  <Icon name="heartPicta" />
                </span>
                <span className={styles.heart3}>
                  <Icon name="heartPicta" />
                </span>
                <span className={styles.heart4}>
                  <Icon name="heartPicta" />
                </span>
              </>
            ) : null}
            <Link href={nav.url} onClick={closeMenu} className={styles.navLink}>
              {nav.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
