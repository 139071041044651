'use client';

import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import getBasePath from '@/utils/getBasePath';

import styles from './styles.module.scss';

export default function PartnerLogoWrapper({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const isHomePage = pathname === getBasePath();

  return (
    <Link
      prefetch={!isHomePage}
      href="/"
      className={clsx(
        styles.logo,
        styles[process.env.NEXT_PUBLIC_PARTNER || ''],
      )}
      aria-label={`${process.env.NEXT_PUBLIC_PARTNER} homepage`}
    >
      {children}
    </Link>
  );
}
