'use client';

import Icon from '@/components/Icon';

import type { MenuState } from '../index';

import styles from './styles.module.scss';

type Props = {
  onToggle: () => void;
  isOpen: boolean;
  menuState: MenuState;
};

function BurgerButton({ onToggle, isOpen, menuState }: Props) {
  return (
    <div className={styles.container} data-state={menuState}>
      <button
        type="button"
        className={styles.button}
        aria-label="Toggle Menu"
        onClick={onToggle}
      >
        {isOpen ? <Icon name="removeCross" /> : <Icon name="burgerMenu" />}
      </button>
    </div>
  );
}

export default BurgerButton;
